import { stableEmptyObject } from "@hex/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../../../hex-components";
import { CategoryLabel, CategoryLabelProps } from "../CategoryLabel";

import { AppliedLabelsOverflowCount } from "./AppliedLabelsOverflowCount.js";
import { AppliedLabelsWrapper, CollapsedAppliedLabelsWrapper } from "./styled";
import { AppliedCategoriesProps } from "./types.js";

export const AppliedCategories: React.FunctionComponent<
  AppliedCategoriesProps
> = ({
  appliedCategories,
  className,
  collapseAfter = 2,
  condensed,
  isExcludeFilter = false,
  minimal,
  muted = false,
  showOverflowCount = false,
}) => {
  const collapsedCategories = useMemo(
    () =>
      showOverflowCount && collapseAfter != null
        ? appliedCategories.slice(0, collapseAfter)
        : appliedCategories,
    [appliedCategories, collapseAfter, showOverflowCount],
  );

  if (isExcludeFilter) {
    return <AppliedLabelsWrapper>None</AppliedLabelsWrapper>;
  }

  if (
    !minimal &&
    (collapseAfter == null || appliedCategories.length <= collapseAfter)
  ) {
    return (
      <AppliedCategoriesWrapper $minimal={minimal} className={className}>
        {appliedCategories.map((category) => (
          <CategoryLabel
            key={category.id}
            category={category}
            condensed={condensed}
            minimal={minimal}
            muted={muted}
          />
        ))}
      </AppliedCategoriesWrapper>
    );
  }

  return (
    <HexTooltip
      content={
        <AppliedLabelsWrapper $inTooltip={true} className={className}>
          {appliedCategories.map((category) => (
            <CategoryLabel
              key={category.id}
              category={category}
              condensed={condensed}
            />
          ))}
        </AppliedLabelsWrapper>
      }
      placement="bottom"
    >
      <CollapsedAppliedLabelsWrapper>
        {collapsedCategories.map((category) => (
          <AppliedCategoryLabel
            key={category.name}
            category={category}
            condensed={condensed}
            minimal={true}
            overrideDescriptionTooltip={true}
          />
        ))}
        {showOverflowCount && collapseAfter != null && (
          <AppliedLabelsOverflowCount
            collapseAfter={collapseAfter}
            muted={muted}
            numberOfAppliedLabels={appliedCategories.length}
          />
        )}
      </CollapsedAppliedLabelsWrapper>
    </HexTooltip>
  );
};

const AppliedCategoryLabel = React.memo<
  Pick<CategoryLabelProps, "condensed" | "minimal" | "category" | "muted"> & {
    overrideDescriptionTooltip?: boolean;
  }
>(function AppliedStatusLabel({
  category,
  condensed,
  minimal,
  muted,
  overrideDescriptionTooltip,
}) {
  // Disable tooltip when status is minimized since we show a tooltip summarizing all applied minimized statuses
  // If not minimal, leave undefined, so CategoryLabel can decide whether to show the tooltip
  const descriptionTooltipProps = useMemo(
    () =>
      overrideDescriptionTooltip
        ? { disabled: true }
        : stableEmptyObject<"disabled", boolean>(),
    [overrideDescriptionTooltip],
  );

  return (
    <StyledCategoryLabel
      category={category}
      condensed={condensed}
      descriptionTooltipProps={descriptionTooltipProps}
      minimal={minimal}
      muted={muted}
    />
  );
});

const StyledCategoryLabel = styled(CategoryLabel)`
  padding: ${({ minimal }) => (minimal ? 0 : undefined)};
  width: ${({ minimal }) => (minimal ? "auto" : undefined)};
`;

const AppliedCategoriesWrapper = styled(AppliedLabelsWrapper)`
  gap: 2px;
`;
