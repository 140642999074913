import React, { Suspense } from "react";
import styled from "styled-components";

import { HexSpinner } from "../../../hex-components";
import { reactLazyRetry } from "../../../util/reactLazyRetry";

// eslint-disable-next-line no-restricted-imports
import type { EmojiPickerDoNotUseProps } from "./EmojiPickerDoNotUse";
// eslint-disable-next-line no-restricted-imports
export type { EmojiItem } from "./EmojiPickerDoNotUse";

const LazyEmojiPicker = reactLazyRetry(
  () => import(/* webpackPrefetch: true */ "./EmojiPickerDoNotUse"),
);

const StyledSpinner = styled(HexSpinner)`
  padding: 8px;
`;

export type EmojiPickerProps = EmojiPickerDoNotUseProps;

export const EmojiPicker: React.ComponentType<EmojiPickerProps> = React.memo(
  function EmojiPicker(props: EmojiPickerProps) {
    return (
      <Suspense fallback={<StyledSpinner />}>
        <LazyEmojiPicker {...props} />
      </Suspense>
    );
  },
);
