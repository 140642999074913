import styled from "styled-components";

export const ProjectCardGrid = styled.div<{ $minColumnWidth?: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ $minColumnWidth = "400px" }) => $minColumnWidth}, 1fr)
  );

  gap: 12px;

  @media all and (width > ${({ theme }) => `${theme.mediaQuery.LARGE}px`}) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(${({ $minColumnWidth = "500px" }) => $minColumnWidth}, 1fr)
    );
  }

  @media all and (width >= ${({ theme }) =>
      `${theme.mediaQuery.MEDIUM}px`}) and (width <= ${({ theme }) =>
      `${theme.mediaQuery.LARGE}px`}) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(${({ $minColumnWidth = "350px" }) => $minColumnWidth}, 1fr)
    );
  }

  @media all and (width < ${({ theme }) => `${theme.mediaQuery.MEDIUM}px`}) {
    display: flex;
    flex-direction: column;
  }
`;
