import { ProjectLanguage } from "@hex/common";
import React from "react";

import { CyData } from "../../util/cypress";
import { RBetaTag } from "../common/BetaTag";
import { Picker, PickerItem } from "../common/Picker";

const LANGUAGE_INFO: PickerItem<ProjectLanguage>[] = [
  {
    title: "Python + SQL",
    key: ProjectLanguage.PYTHON,
    description: "A project kernel for writing Python and SQL code.",
  },
  {
    title: "R + SQL",
    key: ProjectLanguage.R,
    description: "A project kernel for writing R and SQL code.",
    rightElement: <RBetaTag />,
  },
];

interface ProjectLanguagePickerProps {
  appliedLanguage: ProjectLanguage;
  onSelectLanguage: (newLanguage: ProjectLanguage) => void;
}

export const ProjectLanguagePicker: React.FunctionComponent<
  ProjectLanguagePickerProps
> = ({ appliedLanguage, onSelectLanguage }) => {
  return (
    <Picker
      dataCy={CyData.SELECT_PROJECT_LANGUAGE}
      items={LANGUAGE_INFO}
      label="Language"
      selectedItem={appliedLanguage}
      onSelect={onSelectLanguage}
    />
  );
};
