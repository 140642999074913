import styled from "styled-components";

/**
 * @description Conditionally shows it's child when rendered as child of `<ShowOnHoverContainer />`
 * @param {Object} props - ShowOnHover props
 * @param {boolean=} props.$show - show children regrdless of `:hover` state
 * @param {string} props.datacontroller - maps to id of controlling container managing `:hover` state
 * @example // default behavior and using $show property
 * <ShowOnHoverContainer>
 * <ShowOnHover>visible on hover</ShowOnHover>
 * <ShowOnHover $show={true}>always visible</ShowOnHover>
 * </ShowOnHoverContainer>
 */

export const ShowOnHover = styled.div<{
  $show?: boolean;
  "data-controller": string;
}>`
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: opacity ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
`;

export const StickyShowOnHover = styled(ShowOnHover)`
  position: ${({ $show = false }) => ($show ? "static" : "absolute")};
  right: 0;
`;

/**
 * @description Wrapper that sets opacity children `<ShowOnHover/>` components to 100% when hover is triggered, or on devices when hover is not available.
 */

export const ShowOnHoverContainer = styled.div<{ id: string }>`
  position: relative;
  ${ShowOnHover} {
    @media only screen and (hover: none) {
      opacity: 1;
    }
  }

  &:hover ${ShowOnHover} {
    &[data-controller="${({ id }) => id}"] {
      opacity: 1;
    }
  }

  ${StickyShowOnHover} {
    @media only screen and (hover: none) {
      position: static;
    }
  }

  &:hover ${StickyShowOnHover} {
    &[data-controller="${({ id }) => id}"] {
      position: static;
    }
  }
`;
