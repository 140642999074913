import { Classes } from "@blueprintjs/core";
import React, { useCallback } from "react";
import styled from "styled-components";

import { HexButton, HexKeyCombo } from "../../hex-components";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";
import { SearchIcon } from "../icons/CustomIcons";

const SearchButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;

  cursor: pointer;

  justify-content: center;

  .${Classes.BUTTON} {
    flex: 1;
    max-width: 500px;

    .${Classes.BUTTON_TEXT} {
      justify-content: center;
    }
  }

  &:hover {
    &&&& .${Classes.BUTTON} {
      background-color: ${({ theme }) => theme.hoverColor};
    }
    .${Classes.BUTTON_TEXT} {
      color: ${({ theme }) => theme.fontColor.MUTED};
      .${Classes.KEY_COMBO} {
        border: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};
      }
    }
  }
`;

const SearchButton = styled(HexButton)`
  display: flex;
  height: 30px;

  .${Classes.BUTTON_TEXT} {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
  }

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    gap: 0 !important;
  }
`;

const StyledHexKeyCombo = styled(HexKeyCombo)`
  > .${Classes.ICON}:first-child:last-child {
    margin: 0 1px 0 0;
  }
`;

const SearchPrompt = styled.span`
  gap: 10px;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    display: none;
  }
`;

interface WorkspaceSearchButtonProps {
  location?: "nav-bar" | "quick-nav";
}

export const WorkspaceSearchButton: React.FunctionComponent<
  WorkspaceSearchButtonProps
> = ({ location = "nav-bar" }) => {
  const { openDialog: openWorkspaceSearchDialog } =
    useDialog("workspace-search");

  const toggleWorkspaceSearch = useCallback(() => {
    openWorkspaceSearchDialog();
  }, [openWorkspaceSearchDialog]);

  return (
    <SearchButtonContainer onClick={toggleWorkspaceSearch}>
      <SearchButton
        alignText="left"
        fill={location === "quick-nav"}
        icon={<SearchIcon />}
        minimal={false}
        subtle={true}
      >
        <SearchPrompt>
          Search your workspace...
          <StyledHexKeyCombo combo={HotKeys.QUICK_SEARCH} />
        </SearchPrompt>
      </SearchButton>
    </SearchButtonContainer>
  );
};
