import React from "react";
import styled from "styled-components";

export const AppliedLabelsOverflowCount = React.memo<{
  collapseAfter: number;
  numberOfAppliedLabels: number;
  className?: string;
  muted?: boolean;
}>(function CollapsedLabelCount({
  className,
  collapseAfter,
  muted,
  numberOfAppliedLabels,
}) {
  const showCount = numberOfAppliedLabels - collapseAfter > 0;
  return showCount ? (
    <StyledSpan $muted={muted} className={className}>
      +{numberOfAppliedLabels - collapseAfter}
    </StyledSpan>
  ) : null;
});

const StyledSpan = styled.span<{ $muted?: boolean; $condensed?: boolean }>`
  color: ${({ $muted, theme }) => ($muted ? theme.fontColor.MUTED : "inherit")};
  display: inline-flex;
  align-self: center;
`;
