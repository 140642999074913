import { HexColor } from "@hex/common";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const OrganizationIcon = styled.div<{
  $color: HexColor;
  $condensed?: boolean;
}>`
  position: relative;

  display: inline-block;
  flex: none;

  color: ${({ theme }) => theme.fontColor.DEFAULT};

  &::before {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: "";

    ${({ $color, theme }) => css`
      background-color: ${rgba(theme.statusCategoryColor[$color], 0.16)};
      border: 1px solid ${rgba(theme.statusCategoryColor[$color], 0.8)};
    `}
  }
`;

export const CategoryIcon = styled(OrganizationIcon)`
  width: 6px;
  height: 14px;
  border-radius: 1px;

  ${({ $condensed }) =>
    $condensed &&
    css`
      height: 12px;
      width: 5px;
    `}

  &::before {
    width: 100%;
    height: 100%;
    border-radius: 1px;
  }
`;

export const StatusIcon = styled(OrganizationIcon)`
  width: 10px;
  height: 10px;

  border: none;

  border-radius: 50%;

  &::before {
    width: 100%;
    height: 100%;

    border-radius: 50%;
  }
`;
