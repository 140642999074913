import { CollectionRole, OrgRole, ProjectRole } from "@hex/common";
import { rgba } from "polished";
import React, { ReactNode, useCallback } from "react";
import styled, { useTheme } from "styled-components";

import { HexMenuItem } from "../../hex-components";
import { CyData } from "../../util/cypress";
import { typedMemo } from "../../util/typedMemo";
import { TickIcon } from "../icons/CustomIcons";

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Status = styled.div`
  flex: none;
`;

const Text = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 6px;
`;

const Name = styled.div`
  line-height: 16px;
`;

const Body = styled.div<{ $active: boolean; $disabled: boolean }>`
  display: flex;

  flex-direction: column;
  gap: 10px;

  color: ${({ $active, $disabled, theme }) =>
    $active
      ? rgba(theme.menuItem.activeText, 0.7)
      : $disabled
        ? rgba(theme.fontColor.MUTED, 0.4)
        : theme.fontColor.MUTED};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;
`;

const StyledMenuItem = styled(HexMenuItem)`
  position: relative;

  padding: 8px;
`;
export interface RoleMenuItemProps<
  R extends OrgRole | ProjectRole | CollectionRole,
> {
  role: R | null;
  currentRole: R | null;
  onSelectRole: (newRole: R | null) => void;
  label: string;
  badge?: ReactNode;
  description: string;
  disabled?: boolean;
  additionalActions?: ReactNode;
}

export const RoleMenuItem = typedMemo(function RoleMenuItem<
  R extends OrgRole | ProjectRole | CollectionRole,
>({
  additionalActions,
  badge,
  currentRole,
  description,
  disabled = false,
  label,
  onSelectRole,
  role,
}: RoleMenuItemProps<R>) {
  const selectThisRole = useCallback(() => {
    onSelectRole(role);
  }, [onSelectRole, role]);

  const theme = useTheme();
  const isActive = role === currentRole;

  return (
    <StyledMenuItem
      active={isActive}
      data-cy={`${CyData.ROLE_PREFIX}${role}`}
      disabled={disabled}
      multiline={true}
      text={
        <Info>
          <Text>
            <Header>
              <Name>{label}</Name>
              {badge}
            </Header>
            <Body $active={isActive} $disabled={disabled}>
              <Description>{description}</Description>
              {additionalActions}
            </Body>
          </Text>
          <Status>
            {isActive ? <TickIcon color={theme.menuItem.activeText} /> : null}
          </Status>
        </Info>
      }
      onClick={selectThisRole}
    />
  );
});
