import { retry } from "@hex/common";
import microMemoize from "micro-memoize";

export interface EmojiItem {
  name: string;
  emoji: string;
}

export const getEmojis = microMemoize(async (): Promise<EmojiItem[]> => {
  const { emojis } = await retry({
    func: () => import("./emojis"),
    retryCount: 5,
    interval: 100,
  });

  return emojis.map(([name, emoji]) => ({ name, emoji }));
});
