import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";

import { useOnClickProps } from "../../../hooks/useOnClickProps";
import { HomeTab, Routes } from "../../../route/routes";

export type LabelNavTarget = "_blank" | "_self";

export type LabelNavProps = {
  homeSubView?: HomeTab;
  target?: LabelNavTarget;
};

export type LabelNavOnClickProps =
  | ReturnType<typeof useOnClickProps>
  | {
      onClick: (e: React.MouseEvent) => void;
    };

export function useLabelNavProps({
  categoryNames,
  homeSubView,
  statusNames,
  target = "_blank",
}: {
  statusNames?: readonly string[];
  categoryNames?: readonly string[];
  homeSubView?: HomeTab;
  target?: LabelNavTarget;
}): LabelNavOnClickProps {
  const history = useHistory();
  const routeParams = useParams<{ subView?: HomeTab }>();
  const subView: HomeTab =
    homeSubView ?? (routeParams.subView as HomeTab | undefined) ?? "home";

  const { href: targetBlankHref } = useOnClickProps({
    target: "_blank",
    to: Routes.HOME.getUrl({
      subView,
      queryParams: {
        statusNames,
        categoryNames,
      },
    }),
  });

  // The consumers for this hook (StatusLabelNavButton, CategoryLabelNavButton)
  // use the <button> component (can't use <a> as they're often rendered within
  // other <a> elements, and nested <a>s are illegal) do not have `href` prop
  // so we translate it to window.open(href, "_blank")
  const targetBlankClickProps = useMemo(
    () => ({
      onClick: () => {
        window.open(targetBlankHref, "_blank");
      },
    }),
    [targetBlankHref],
  );

  const targetSelfClickProps = useMemo(
    () => ({
      onClick: (e: React.MouseEvent) => {
        Routes.push(history, Routes.HOME, {
          subView,
          queryParams: {
            statusNames: statusNames,
            categoryNames: categoryNames,
          },
        });
        // prevnt click event from propagating upwards if component that uses
        // this hook is rendered within another clickable element
        e.stopPropagation();
        // if rendered inside <a> prevent navigating away to the href of parent
        e.preventDefault();
      },
    }),
    [history, subView, statusNames, categoryNames],
  );

  return target === "_blank" ? targetBlankClickProps : targetSelfClickProps;
}
