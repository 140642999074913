import { Classes } from "@blueprintjs/core";
import styled, { css } from "styled-components";

export const AppliedLabelsWrapper = styled.div<{
  $inTooltip?: boolean;
  $minimal?: boolean;
}>`
  display: flex;
  align-items: center;
  min-width: 0;

  ${({ $inTooltip }) =>
    $inTooltip &&
    css`
      flex-wrap: wrap;
    `}

  .${Classes.POPOVER_TARGET} {
    display: flex;
  }

  ${({ $minimal }) =>
    $minimal &&
    css`
      gap: 1px;
    `}
`;

export const CollapsedAppliedLabelsWrapper = styled(AppliedLabelsWrapper)`
  gap: 3px;
`;
