import { ORG_ROLE_ORDERING, OrgRole } from "@hex/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import { MyOrgRoleDependentRenderer } from "../MyOrgRoleDependentRenderer";

import { StatusLabel, StatusLabelProps } from "./StatusLabel";
import type { LabelNavProps } from "./useLabelNavProps";
import { useLabelNavProps } from "./useLabelNavProps";

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- filter
const ORG_ROLES_WITH_NAV_ACCESS = ORG_ROLE_ORDERING.filter(
  (orgRole): boolean =>
    ({
      [OrgRole.ADMIN]: true,
      [OrgRole.MANAGER]: true,
      [OrgRole.EDITOR]: true,
      [OrgRole.EXPLORER]: true,
      [OrgRole.MEMBER]: true,
      [OrgRole.GUEST]: false,
      [OrgRole.ANONYMOUS]: false,
    })[orgRole],
);

const StatusButton = styled.div`
  display: flex;

  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  &:active {
    background-color: ${({ theme }) => theme.activeColor};
  }
`;

const StatusNonButton = styled.div`
  display: flex;
`;

export type StatusLabelNavButtonProps = LabelNavProps & StatusLabelProps;

export const StatusLabelNavButton: React.ComponentType<StatusLabelNavButtonProps> =
  React.memo(function StatusLabelNavButton({
    className,
    homeSubView,
    status,
    target,
    ...statusOmittedLabelProps
  }: StatusLabelNavButtonProps) {
    const statusNames = useMemo(() => {
      return [status.name];
    }, [status]);

    const onClickProps = useLabelNavProps({
      statusNames,
      homeSubView,
      target,
    });

    const label = <StatusLabel {...statusOmittedLabelProps} status={status} />;
    return (
      <MyOrgRoleDependentRenderer allowedOrgRoles={ORG_ROLES_WITH_NAV_ACCESS}>
        {(allowed) =>
          allowed ? (
            <StatusButton {...onClickProps} className={className}>
              {label}
            </StatusButton>
          ) : (
            <StatusNonButton className={className}>{label}</StatusNonButton>
          )
        }
      </MyOrgRoleDependentRenderer>
    );
  });
