import { gql } from "@apollo/client";
import { KernelImage, ProjectLanguage } from "@hex/common";
import React from "react";

import { Picker } from "../common/Picker";

import { useGetKernelImagesForPickerQuery } from "./KernelImagePicker.generated";

gql`
  query GetKernelImagesForPicker($projectLanguage: ProjectLanguage!) {
    getDefaultKernelImage(projectLanguage: $projectLanguage)
    getKernelImages(projectLanguage: $projectLanguage) {
      id
      humanName
    }
  }
`;

interface KernelImagePickerProps {
  projectLanguage: ProjectLanguage;
  currentKernelImage: KernelImage | null;
  onSelect: (newKernelImage: KernelImage) => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

export const KernelImagePicker: React.FunctionComponent<
  KernelImagePickerProps
> = ({
  currentKernelImage,
  disabled,
  hideLabel = false,
  onSelect,
  projectLanguage,
}) => {
  const { data, error, loading } = useGetKernelImagesForPickerQuery({
    variables: {
      projectLanguage,
    },
  });
  return (
    <Picker
      disabled={disabled}
      error={!!error}
      items={
        data?.getKernelImages.map((kernelImage) => ({
          title: kernelImage.humanName,
          key: kernelImage.id,
        })) ?? []
      }
      label={!hideLabel ? "Image" : ""}
      loading={loading}
      selectedItem={currentKernelImage ?? data?.getDefaultKernelImage ?? null}
      onSelect={onSelect}
    />
  );
};
